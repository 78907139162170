
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default defineComponent({
  name: "Detail Sekolah",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading, 
  },

  data() {
    return {

      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      
      url: "",
      detail: {
        name: "",
        phone: "",
        email: "",
        initial: "",
        picName: "",
        picPhone: "",
        picEmail: "",
        schoolType: "",
        fundingName: "",
        salesArea: "",
        picProduct: "",
        status: "",
        targetLive: "",
        customApp: "",
        progress: "",
        address: {
          province: "",
          city: "",
          regency: "",
          detail: "",
        },
        bankData: {},
        gps: {
          longitude: "",
          latitude: "",
        },
        npwp: {
          number: "",
        },
        referenceFrom: "",
        note: "",
        creatorName: "",
        createTime: "",
      },
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
    
    this.getIdSekolah();
  },

  methods: {
    getIdSekolah() {

      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();

      ApiService.getWithoutSlug(
        "crmv2/main_school/admin_school/school/" + JSON.parse(localStorage.getItem("user_account")!).schoolId
      )
        .then((res) => {
          this.detail.name = res.data.name;
          this.detail.phone = res.data.phone;
          this.detail.email = res.data.email;
          this.detail.initial = res.data.initial;
          this.detail.picName = res.data.picName;
          this.detail.picPhone = res.data.picPhone;
          this.detail.picEmail = res.data.picEmail;
          this.detail.schoolType = res.data.schoolType;
          this.detail.picProduct = res.data.picProduct;
          this.detail.targetLive = res.data.targetLive;
          this.detail.customApp = res.data.customApp;
          this.detail.progress = res.data.progress;
          this.detail.status = res.data.status;
          this.detail.fundingName = res.data.fundingName;
          this.detail.salesArea = res.data.salesAreaName;

          this.detail.address.province = res.data.address.province;
          this.detail.address.city = res.data.address.city;
          this.detail.address.regency = res.data.address.regency;
          this.detail.address.detail = res.data.address.detail;

          this.detail.bankData = res.data.listBankName;
          this.detail.gps.longitude = res.data.gps.longitude;
          this.detail.gps.latitude = res.data.gps.latitude;
          this.detail.npwp.number = res.data.npwp.number;

          this.url = ApiService.vueInstance.axios.defaults.baseURL + 'crmv2/main_school/school/school/download_npwp?schoolId=' +  this.$route.params._id + '&imgFile=' + res.data.npwp.imageUrl;

          this.detail.note = res.data.note;
          this.detail.createTime = res.data.createTime.substring(0, 10);
          this.detail.creatorName = res.data.creatorName;
          this.detail.referenceFrom = res.data.referenceFrom;

          this.isLoading = false;

        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push('/sign-in');
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },

  },
});
