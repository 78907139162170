
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import DetailSchool from "@/components/1crm/1sekolah/sekolah/detail-sekolah.vue";
import DetailProgressSchool from "@/components/1crm/1sekolah/sekolah/detail-progress-sekolah.vue";
import Detailpks from "@/components/1crm/sekolah/detail-pks.vue";
import KonfigSchool from "@/components/1crm/sekolah/konfig-detail.vue";


interface items {
  detailSekolah2: Boolean;
  progress2: Boolean;
  detailpks2: Boolean;
  konfig2: Boolean;

}

export default defineComponent({
  name: "Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    DetailSchool,
    DetailProgressSchool,
    Detailpks,
    KonfigSchool,
    
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    let items = reactive<items>({
      detailSekolah2: true,
      progress2: false,
      detailpks2: false,
      konfig2: false,


    });

    const detailSekolah2 = () => {
      items.detailSekolah2 = true;
      items.progress2 = false;
      items.detailpks2 = false;
      items.konfig2 = false;

    };

    const progress = () => {
      items.progress2 = true;
      items.detailSekolah2 = false;
      items.detailpks2 = false;
      items.konfig2 = false;

    };

    const DokumenPks2 = () => {
      items.progress2 = false;
      items.detailSekolah2 = false;
      items.detailpks2 = true;
      items.konfig2 = false;

    };
    const konfig2 = () => {
      items.progress2 = false;
      items.detailSekolah2 = false;
      items.detailpks2 = false;
      items.konfig2 = true;
    };


    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_SEKOLAH);
      setCurrentPageBreadcrumbs("Sekolah", ["Data"]);
    });

    return {
      submitButton,
      detailSekolah2,
      DokumenPks2,
      items,
      progress,
      konfig2,
    };
  },
});
